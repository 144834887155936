import { enqueueSnackbar } from 'notistack'
import useSWR from 'swr'
import client from '~/client'

// sharedProps.swr.immutable

export default function useCustomPrices() {
  const userInfo = client.getUserInfo()

  const { mutate, ...rest } = useSWR(
    `customPrice/?companyId=${userInfo.companyId}`,
    client.list
  )

  const deleteCustomPrice = ({ customPriceId, productId }) => {
    mutate(
      async () => {
        try {
          const result = await client.delete('customPrice', { customPriceId })
          enqueueSnackbar(`Custom price deleted for ${productId}`)
          return result
        } catch (e) {
          enqueueSnackbar(e, { variant: 'error' })
          throw e
        }
      },
      {
        populateCache: (result, currentData) =>
          currentData.filter(row => row.customPriceId !== customPriceId),
        optimisticData: currentData =>
          currentData.filter(row => row.customPriceId !== customPriceId),
      }
    )
  }

  const saveCustomPrice = customPrice => {
    mutate(
      async () => {
        const result = await client.put('customPrice', customPrice)
        enqueueSnackbar(`Custom price updated for ${customPrice.productId}`)
        return result
      },
      {
        populateCache: (result, currentData) => [
          ...currentData.filter(
            row => row.customPriceId !== result.customPriceId
          ),
          result,
        ],
        optimisticData: currentData => [
          ...currentData.filter(
            row => row.customPriceId !== customPrice.customPriceId
          ),
          customPrice,
        ],
        // currentData.map(row =>
        //   row.customPriceId === customPrice.customPriceId ? customPrice : row
        // ),
      }
    )
  }

  return { mutate, ...rest, deleteCustomPrice, saveCustomPrice }
}
