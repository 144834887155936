import {
  Box,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

import { ArrowBack } from '@mui/icons-material'
import React from 'react'
import {
  MultiSelectElement,
  TextFieldElement,
  useFormContext,
} from 'react-hook-form-mui'
import { Link, generatePath, useMatches } from 'react-router-dom'
import productsSchema from 'sanity/schemas/product'
import useSanityPageQuery from '~/client/useSanityPageQuery'
import AutoTable from '~/components/Data/AutoTable'
import SearchFormContainer from '~/components/Data/Filter/SearchFormContainer'
import Pagination from '~/components/Data/Pagination'
import PageHeader from '~/components/Layouts/PageHeader'
import sharedProps from '~/components/sharedProps'
import currentGeneration from '~/config/currentGeneration'
import useSearchParams from '~/core/router/useSearchParams'
import { findOptionsMap } from '~/core/sanity/findOptions'
import buildSanityProductQuery from '~/routes/Admin/ProductCatalogue/buildSanityProductQuery'
import ProductInformation from '~/routes/Admin/ProductCatalogue/ProductInformation'
import useSanityQuery from '~/client/useSanityQuery'

const sanityCategories = findOptionsMap(['products', 'spec', 'category'])

const allCategories = {
  label: 'All products',
  value: '',
  description: 'All products in catalog',
}

const legacyCategory = {
  label: 'Legacy products',
  value: 'legacy',
  description: 'Legacy products not in current catalog',
}

const activeCategoriesQuery =
  '{' +
  sanityCategories
    .map(
      ({ value }) =>
        `"${value}": count(*[_type == "products" && spec.category == "${value}"])`
    )
    .join(',') +
  '}'

const categories = [allCategories, ...sanityCategories, legacyCategory]

const getCategory = category =>
  categories.find(c => c.value === category) || allCategories

const specFields = productsSchema.fields.find(
  ({ name }) => name === 'spec'
).fields

const getSearchFields = category =>
  specFields
    .filter(
      ({ visibleCategories, type, options }) =>
        (!visibleCategories || visibleCategories.includes(category)) &&
        type === 'array' &&
        Array.isArray(options?.list)
    )
    .map(field => ({
      name: field.name,
      // title: field.title,
      type: field.of[0].type || 'string',
      label: field.title,
      options: field.options.list.map(option => ({
        id: option.value,
        label: option.title,
      })),
    }))

export const highlightFieldWithValueSx = {
  '& input[value]:not([value=""]) ~ fieldset': {
    borderColor: 'primary.light',
    borderWidth: 2,
    color: 'primary.main',
  },
  '.MuiFormControl-root:has(&) > label[data-shrink="true"]': {
    color: 'primary.main',
  },
}

export default function ProductCatalogue({ ...rest }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { category = '', modelNumber } = searchParams

  const [text, setText] = React.useState('') // is this even used?

  const [search, setSearch] = React.useState({})

  const query =
    category === 'legacy'
      ? buildSanityProductQuery({
          params: { spec: search.spec },
          text: search.text,
          textFields: ['modelNumber', 'title'],
          filter: `generation < ${currentGeneration}`,
        })
      : buildSanityProductQuery({
          params: { spec: { category, ...search.spec } },
          text: search.text,
          textFields: ['modelNumber', 'title'],
        })

  const { data: products, pagination, isLoading } = useSanityPageQuery(query)

  React.useEffect(() => void pagination.setPage(0), [category, search, text])

  const navigateCategory = name =>
    setSearchParams(({ legacy, modelNumber, category, ...p }) =>
      name ? { ...p, category: name } : p
    )

  const navigateProduct = product =>
    setSearchParams(({ modelNumber, ...p }) =>
      product?.modelNumber ? { ...p, modelNumber: product.modelNumber } : p
    )

  const categorySearchFields = React.useMemo(
    () => getSearchFields(category),
    [category]
  )

  const { data: activeCategories } = useSanityQuery(activeCategoriesQuery, {
    revalidateOnFocus: false,
  })

  return (
    <>
      <PageHeader />
      <Stack
        spacing={2}
        direction="row"
        sx={{ '& td:first-child': { width: '20%', minWidth: 120 } }}
      >
        <Card sx={{ flexShrink: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 220,
              height: 48,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                pl: 2,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigateProduct()}
            >
              <ArrowBack
                sx={{
                  transition: 'width .3s, margin .3s',
                  display: 'block',
                  width: 0,
                  ml: 0,
                  ...(modelNumber && {
                    width: 18,
                    mr: 1,
                  }),
                }}
              />
              Product categories
            </Typography>
          </Box>
          <List dense>
            {categories
              .filter(category => activeCategories?.[category.value] !== 0)
              .map(({ label, value }) => (
                <ListItem disablePadding key={value}>
                  {/* <ParamLink params={{ category: value, modelNumber: '' }}> */}
                  <ListItemButton
                    // component={ParamLink}
                    // params={{ category: value, modelNumber: '' }}
                    onClick={() => navigateCategory(value)}
                    selected={!modelNumber && category == value}
                    sx={{
                      '& span': {
                        display: 'block',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                  {/* </ParamLink> */}
                </ListItem>
              ))}
          </List>
        </Card>

        {modelNumber ? (
          <Box {...rest} sx={{ flexGrow: 1, position: 'relative' }}>
            <ProductInformation />
          </Box>
        ) : (
          <Stack direction="column" spacing={2} sx={{ flexGrow: 1 }}>
            <Card
              {...sharedProps.card}
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                // alignItems: 'center',
              }}
            >
              <div>
                <Typography variant="h4" gutterBottom>
                  {getCategory(category).label || 'All products'}
                </Typography>
                <Typography sx={{ color: '#666' }}>
                  {getCategory(category).description}
                </Typography>
              </div>
              {/* <TextField
              sx={{ minWidth: '30%' }}
              onChange={e => setText(e.target.value)}
              value={text}
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setText('')}>
                      {text ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
            </Card>
            <Card {...sharedProps.card}>
              <SearchFormContainer setSearch={setSearch} compact>
                {/* <FormReset dependencies={[category]} /> */}
                <TextFieldElement
                  name="text"
                  label="Text search"
                  size="small"
                  sx={{ gridColumn: 'span 2', ...highlightFieldWithValueSx }}
                />
                {categorySearchFields.map(field => (
                  <MultiSelectElement
                    sx={highlightFieldWithValueSx}
                    key={field.name}
                    size="small"
                    {...field}
                    name={`spec.${field.name}`}
                    showCheckbox
                    itemLabel="label"
                    renderValue={value =>
                      value
                        .map(
                          v =>
                            field.options.find(option => option.id === v)?.label
                        )
                        .join(', ')
                    }
                  />
                ))}
              </SearchFormContainer>
              {/* <LoadingIndicators isLoading={isLoading} /> */}
              <AutoTable
                sx={{ width: '100%' }}
                columns={['modelNumber', 'title']}
                data={products}
                onRowClick={navigateProduct}
              />
              <Pagination {...pagination} />
            </Card>
          </Stack>
        )}
      </Stack>

      {/* <Dialog
        open={!!modelNumber}
        onClose={() => navigateProduct()}
        sx={{}}
        PaperProps={{ sx: { p: 2, width: 600 } }}
      >
        <IconButton {...sharedProps.button.x} onClose={() => navigateProduct()}>
          <CloseIcon onClick={() => navigateProduct()} />
        </IconButton>
        <ProductInformation />
      </Dialog> */}

      {/* <Container sx={{ height: '90vh', pb: 3 }}>
        <DataGrid
          autoPageSize
          columnVisibilityModel={Object.fromEntries(
            ['reps', 'firms', 'contractors'].flatMap(k =>
              ['USD', 'GBP', 'EUR'].map(c => [`price.${k}.${c}`, false])
            )
          )}
          rows={formatRows(products)}
          columns={columns}
        />
      </Container> */}
    </>
  )
}

// const columns = formatColumns(productsSchema.fields)

// const resolvedPath = useResolvedPath('.')
// const href = useHref()
// const matches = useMatches()

const ParamLink = ({ params, children, ...rest }) => {
  const match = useMatches().at(-1)
  const fullPath = match.handle.fullPath
  const path = generatePath(fullPath, { ...match.params, ...params })

  return (
    <Link to={path} {...rest}>
      {children}
    </Link>
  )
}

// const useFormReset = deps => {
//   const formContext = useFormContext()
//   const { reset, watch } = formContext
//   const fields = watch()
//   console.log('fields', fields)
//   React.useEffect(() => void formContext.reset(), [deps].flat())
// }

// const FormReset = ({ dependencies }) => {
//   useFormReset(dependencies)
//   return null
// }
