import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import BusinessIcon from '@mui/icons-material/Business'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import GridViewIcon from '@mui/icons-material/GridView'
import HomeIcon from '@mui/icons-material/Home'
import HubOutlinedIcon from '@mui/icons-material/HubOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import PersonIcon from '@mui/icons-material/Person'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
// import FastFilter from '~/routes/Admin/FastFilter'
// import FastQuotePage from 'components/Admin/FastQuote'
import Profile from '~/routes/Admin/Profile'
import CompanyUsers from './routes/Admin/Company/Users'
import StepComplete from './routes/Admin/FastQuote/StepComplete'
import Notifications from './routes/Admin/Notifications'
import NotificationsBadge from './routes/Admin/Notifications/NotificationsBadge'
import SendNotification from './routes/Admin/Notifications/SendNotification'
import Proposals from './routes/Admin/Proposals'

import client from '~/client'

import Error from '~/components/Layouts/Error'
import RootLayout from '~/components/Layouts/RootLayout'

import ForgotPassword from '~/routes/Auth/ForgotPassword'
import ProtectedRoute from '~/routes/Auth/ProtectedRoute'
import ResetPassword from '~/routes/Auth/ResetPassword'
import SignIn from '~/routes/Auth/SignIn'
import SignOut from './routes/Auth/SignOut'

import { Navigate } from 'react-router-dom'
import CompanyAssets from '~/routes/Admin/Company/Assets'
import DistributorPricing from '~/routes/Admin/Company/Pricing'
import CompanyProfile from '~/routes/Admin/Company/Profile'
import Maintenance from '~/routes/Maintenance'

import { roles } from '~/client/StackClient'
import CompanyRelations from '~/routes/Admin/Company/Relations'
// import Dashboard from '~/routes/Admin/Dashboard'
import WelcomePage from '~/routes/Admin/Dashboard/WelcomePage'
import RepGroupCompanies from '~/routes/Admin/RepGroup/Companies'
import RepGroupProposals from '~/routes/Admin/RepGroup/Proposals'
import SalesResources from '~/routes/Admin/SalesResources'
import SalesResourcesAssets from '~/routes/Admin/SalesResources/Assets'
import ViewBoard from '~/routes/Admin/SalesResources/ViewBoard'
import BoardsProvided from '~/routes/Admin/SalesResources/ViewBoardCollection'
import ViewCard from '~/routes/Admin/SalesResources/ViewCard'
import ConfirmSignup from '~/routes/Auth/ConfirmSignup'
import SignUp from '~/routes/Auth/SignUp'
import SuperadminCompanies from '~/routes/SuperAdmin/Companies'
import SuperadminUsers from '~/routes/SuperAdmin/Users'

import TextTemplates from '~/routes/Admin/ProposalSetup/TextTemplates'

import ProductCatalogue from '~/routes/Admin/ProductCatalogue'
import Onboarding from '~/routes/Onboarding'
// import ReplacementGuidePage from '~/routes/Admin/ReplacementGuide'
import { PublishedWithChanges } from '@mui/icons-material'
import isDev from './config/isDev'
// import ExportUserData from './routes/SuperAdmin/Export'
// const mapToEachLevel = (routes, interations = 0) => routes.map(route => (
//   {...route, handle: {...handle, level: 0, children: children.map(mapToEachLevel)}}
//   )

// const LazyFastQuote = React.lazy(() => import('~/components/admin/FastQuote'))

const mapRoutes = (routes, depth = 0, superPath = '') =>
  routes.map(route => ({
    ...route,
    handle: { ...route.handle, depth, fullPath: superPath + route.path },
    children: route.children
      ? mapRoutes(route.children, depth + 1, superPath + route.path)
      : undefined,
  }))

const routes = [
  {
    path: '/',
    element: <RootLayout />,
    handle: { title: '' },
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <SignIn />,
        // handle: { title: 'Dashboard', Icon: EqualizerIcon },
        errorElement: <Error />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'confirm-signup',
        element: <ConfirmSignup />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        handle: { title: null },
        errorElement: <Error />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        handle: { title: null },
        errorElement: <Error />,
      },
      // {
      //   path: 'view-proposal/*',
      //   element: <ViewProposal />,
      //   handle: { title: 'View proposal' },
      //   errorElement: <Error />,
      // },
      {
        path: 'onboarding',
        element: <Onboarding />,
        handle: { nav: false },
        errorElement: <Error />,
        // loader: client.signOut(),
      },
      {
        path: 'view-proposal/:proposalId',
        // element: <ViewProposal />,
        async lazy() {
          const { default: Component } = await import('~/routes/ViewProposal')
          return { Component }
        },
        handle: { title: 'View proposal' },
        errorElement: <Error />,
      },
      {
        path: 'maintenance',
        element: <Maintenance />,
      },
      {
        path: 'admin/',
        element: (
          <ProtectedRoute
            isAllowed={client.isSignedIn}
            redirectPath="/sign-in"
          />
        ),
        handle: { title: 'Admin', Icon: HomeIcon },
        // errorElement: <Error />,
        children: [
          {
            index: true,
            element: <Navigate to="dashboard" />,
          },
          {
            path: 'dashboard/',
            async lazy() {
              const isAdmin = client.hasRoles([
                roles.unico_admin,
                roles.rep_admin,
                roles.rep_sales_manager,
                roles.contract_firm_admin,
              ])
              const { default: Component } = await import(
                isAdmin
                  ? '~/routes/Admin/Dashboard'
                  : '~/routes/Admin/Dashboard/WelcomePage'
              )
              return { Component }
            },
            handle: { title: 'Dashboard', Icon: EqualizerIcon, nav: true },
            errorElement: <Error />,
          },
          {
            path: 'profile/',
            element: <Profile />,
            handle: { title: 'Profile', Icon: PersonIcon, nav: true },
            errorElement: <Error />,
          },
          {
            path: 'notifications/',
            handle: {
              title: 'Notifications',
              Icon: NotificationsNoneIcon,
              nav: true,
              // menuAdornment: loaderData => (
              //   <Badge badgeContent={3} sx={{ ml: 2 }} color="primary" />
              // ),
              menuAdornment: () => <NotificationsBadge />,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Notifications />,
                handle: {
                  // title: 'Notifications',
                  // Icon: NotificationsNoneIcon,
                  nav: true,
                  // menuAdornment: () => <NotificationsBadge />,
                },
              },
            ],
          },
          {
            path: 'fast-quote/',
            // element: <Navigate to="details" />,
            handle: {
              title: 'FastQuote',
              Icon: FormatQuoteIcon,
              nav: () =>
                client.hasRoles([
                  roles.demo_user,
                  roles.contract_firm_admin,
                  roles.contractor,
                  roles.rep_admin,
                  roles.rep_sales_manager,
                  roles.distributor_admin,
                  roles.distributor_user,
                  roles.unico_admin,
                  roles.unico_user,
                  roles.super_admin,
                ]),
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <Navigate to="details" />,
              },
              {
                path: 'success/:proposalId',
                element: <StepComplete />,
                handle: { title: 'Proposal sent' },
                errorElement: <Error />,
              },
              {
                path: '*',
                // index: true,
                // element: <FastQuotePage />,
                // element: <LazyFastQuote />,
                // handle: { title: 'FastQuote', Icon: FormatQuoteIcon },
                errorElement: <Error />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Admin/FastQuote'
                  )
                  return { Component }
                },
              },
            ],
          },
          {
            path: 'replacement-guide',
            async lazy() {
              const { default: Component } = await import(
                '~/routes/Admin/ReplacementGuide'
              )
              return { Component }
            },
            // element: <ReplacementGuidePage />,
            handle: {
              title: 'Replacement guide',
              Icon: PublishedWithChanges,
              nav: true,
              // nav: () => isDev(),
            },
            errorElement: <Error />,
          },
          {
            path: 'proposals/',
            element: <Proposals />,
            handle: {
              title: 'My Proposals',
              Icon: GridViewIcon,
              nav: () =>
                client.hasRoles([
                  roles.demo_user,
                  roles.contract_firm_admin,
                  roles.contractor,
                  roles.rep_admin,
                  roles.rep_sales_manager,
                  roles.distributor_admin,
                  roles.distributor_user,
                  roles.unico_admin,
                  roles.unico_user,
                  roles.super_admin,
                ]),
            },
            errorElement: <Error />,
          },
          {
            path: 'company/',
            handle: {
              title: 'Company',
              Icon: BusinessIcon,
              nav: () =>
                client.hasRoles('_admin') ||
                client.hasRoles(roles.distributor_user),
            },
            errorElement: <Error />,
            children: [
              {
                path: 'details/',
                element: <CompanyProfile />,
                handle: {
                  title: 'Profile',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },

              {
                path: 'users/*',
                element: <CompanyUsers />,
                handle: {
                  title: 'Users',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },
              {
                path: 'pricing/',
                element: <DistributorPricing />,
                handle: {
                  title: 'Pricing',
                  nav: () =>
                    client.hasRoles([
                      roles.distributor_admin,
                      roles.distributor_user,
                      roles.unico_admin,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'assets/',
                element: <CompanyAssets />,
                handle: {
                  title: 'Assets',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },

              {
                path: 'my-distributors/',
                element: <CompanyRelations mode="distributor" />,
                handle: {
                  title: 'My distributors',
                  nav: () =>
                    client.hasRoles([
                      roles.contract_firm_admin,
                      roles.unico_admin,
                    ]),
                },
                errorElement: <Error />,
              },
              {
                path: 'my-contracting-firms/',
                element: <CompanyRelations mode="contractingFirm" />,
                handle: {
                  title: 'My contracting firms',
                  nav: () =>
                    client.hasRoles([
                      roles.distributor_admin,
                      roles.distributor_user,
                      roles.unico_admin,
                    ]),
                },
                errorElement: <Error />,
              },

              // {
              //   path: 'templates/*',
              //   element: <CompanyTemplates />,
              //   handle: { title: 'Templates', nav: true },
              //   errorElement: <Error />,
              // },
            ],
          },
          {
            path: 'proposal-setup/',
            handle: {
              title: 'Proposal setup',
              Icon: SettingsIcon,
              nav: () =>
                client.hasRoles('_admin') ||
                client.hasRoles(roles.distributor_user),
            },
            errorElement: <Error />,
            children: [
              {
                path: 'settings',
                // index: true,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Admin/ProposalSetup/Settings'
                  )
                  return { Component }
                },
                // element: <ProposalSetttings />,
                handle: {
                  title: 'Settings',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },
              {
                path: 'equipment-items/*',
                // element: <EquipmentItems />,
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Admin/ProposalSetup/EquipmentItems'
                  )
                  return { Component }
                },
                handle: {
                  title: 'Non-Unico equipment',
                  nav: () => client.hasRoles([roles.contract_firm_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'equipment-templates/*',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Admin/ProposalSetup/EquipmentTemplates'
                  )
                  return { Component }
                },
                // element: <EquipmentTemplates />,
                handle: {
                  title: 'Non-Unico templates',
                  nav: () => client.hasRoles([roles.contract_firm_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'edit-template/:equipmentTemplateId',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/Admin/ProposalSetup/EditTemplate'
                  )
                  return { Component }
                },
                // element: <EditTemplate />,
                handle: {
                  title: 'Edit template',
                  nav: false,
                },
                errorElement: <Error />,
              },
              {
                path: 'text-templates/*',
                element: <TextTemplates />,
                handle: {
                  title: 'Homeowner messages',
                  nav: () => client.hasRoles('_admin'),
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'rep-group/',
            handle: {
              title: 'Rep Group',
              Icon: HubOutlinedIcon,
              nav: () =>
                client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
            },
            errorElement: <Error />,
            children: [
              {
                path: 'proposals/',
                element: <RepGroupProposals />,
                handle: {
                  title: 'Contractor proposals',
                  nav: () =>
                    client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
                },
                errorElement: <Error />,
              },
              {
                path: 'companies/*',
                element: <RepGroupCompanies />,
                handle: {
                  title: 'Companies',
                  nav: () =>
                    client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
                },
                errorElement: <Error />,
              },
              {
                path: 'users/*',
                element: <SuperadminUsers />,
                handle: {
                  title: 'Users',
                  nav: () =>
                    client.hasRoles([roles.rep_admin, roles.rep_sales_manager]),
                },
                errorElement: <Error />,
              },
            ],
          },

          {
            path: 'fast-filter/',
            // element: <FastFilter />,
            async lazy() {
              const { default: Component } = await import(
                '~/routes/Admin/FastFilter'
              )
              return { Component }
            },
            handle: {
              title: 'FastFilter',
              Icon: FilterListIcon,
              nav: () => client.hasRoles(),
            },
            errorElement: <Error />,
          },
          {
            path: 'product-catalogue/',
            // element: <Products />,
            handle: {
              title: 'Product catalog',
              Icon: AutoStoriesIcon,
              // nav: () => client.hasRoles(),
              nav: true,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <ProductCatalogue />,
              },
              // {
              //   path: ':category/',
              //   element: <ProductCatalogue />,
              // },
              // {
              //   path: ':category/:modelNumber/',
              //   element: <ProductCatalogue />,
              // },
            ],
          },
          {
            path: 'superadmin/',
            handle: {
              title: 'Unico admin',
              Icon: AutoFixHighIcon,
              nav: () => client.hasRoles([roles.unico_admin]),
            },
            errorElement: <Error />,
            children: [
              {
                path: 'companies/*',
                element: <SuperadminCompanies />,
                handle: {
                  title: 'Companies',
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'users/*',
                element: <SuperadminUsers />,
                handle: {
                  title: 'Users',
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'send-notification/',
                element: <SendNotification />,
                handle: {
                  title: 'Send notification',
                  // Icon: EmailIcon,
                  nav: () => client.hasRoles([roles.unico_admin]),
                },
                errorElement: <Error />,
              },
              {
                path: 'export/',
                async lazy() {
                  const { default: Component } = await import(
                    '~/routes/SuperAdmin/Export'
                  )
                  return { Component }
                },
                handle: {
                  title: 'Export',
                  nav: () => client.hasRoles(),
                  // nav: isDev(),
                },
                errorElement: <Error />,
              },
            ],
          },
          {
            path: 'sales-resources/',
            element: <SalesResources />,
            handle: {
              title: 'Sales Resources',
              nav: () => client.hasRoles(),
              // nav: false,
              Icon: DashboardIcon,
            },
            errorElement: <Error />,
            children: [
              {
                index: true,
                element: <BoardsProvided />,
              },
              {
                path: ':boardId/',
                element: <ViewBoard />,
                // children: [
                //   {
                //     path: ':cardId/',
                //     element: <div>View card</div>,
                //   },
                // ],
              },
              {
                path: ':boardId/:cardId/',
                element: <ViewCard />,
              },
            ],
          },
          {
            path: 'sales-resources-assets/',
            element: <SalesResourcesAssets />,
            handle: {
              title: 'Sales Resources Assets',
              nav: () => client.hasRoles(),
              nav: false,
              Icon: DashboardIcon,
            },
            errorElement: <Error />,
          },
          {
            path: 'sign-out',
            element: <SignOut />,
            handle: { title: 'Log Out', Icon: LogoutIcon, nav: true },
            errorElement: <Error />,
            // loader: client.signOut(),
          },
        ],
      },
    ],
  },
  // {
  //   path: 'signin',
  //   element: <SignIn />,
  //   handle: { title: 'Sign in' },
  // },
  // {
  //   path: '/admin/company-profile/add-new-user',
  //   element: (
  //     <div
  //       style={{
  //         position: 'fixed',
  //         background: 'red',
  //         left: 0,
  //         top: 0,
  //         width: 100,
  //         height: 100,
  //         zIndex: 99,
  //       }}
  //     >
  //       plopppp
  //     </div>
  //   ),
  // },
]

export default mapRoutes(routes)
