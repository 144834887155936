import Add from '@mui/icons-material/Add'
import { Button, Card, Stack } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form-mui'
import { useLocation } from 'react-router-dom'
import Drawer from '~/components/Layouts/Drawer'
import PageHeader from '~/components/Layouts/PageHeader'
import Link from '~/config/Link'
import useSubmatch from '~/core/router/useSubmatch'
import AddNewUser from '~/components/Users/AddNewUser'
import DeleteUser from '~/components/Users/DeleteUser'
import EditUser from '~/components/Users/EditUser'
import UsersTable from '~/components/Users/UsersTable'
import { PersonAdd } from '@mui/icons-material'

const formatQuery = (
  query,
  { searchKey = 'email', searchValue, companyId }
) => {
  if (!searchValue && !companyId) return query

  if (!searchValue && companyId) return `companyusers/?companyId=${companyId}`

  const searchQuery = `?searchKey=${searchKey}&searchValue=${searchValue}`
  const companyQuery = companyId ? `&searchCompany=${companyId}` : ''

  return `${query}${searchQuery}${companyQuery}`
}

const defaultValues = {
  searchValue: '',
  searchKey: 'email',
  // companyId: '',
}

export default function SuperadminUsers({}) {
  const { state } = useLocation()

  const formContext = useForm({
    defaultValues: {
      ...defaultValues,
      company: state?.companyId
        ? {
            id: state?.companyId,
            label: state?.companyName,
          }
        : null,
      // searchValue: state.companyName,
    },
  })

  const company = formContext.watch('company')
  const searchValue = formContext.watch('searchValue')

  const query = formatQuery('protected/', {
    companyId: company?.id,
    searchValue,
  })
  // const query = `protected/${
  //   email?.length > 2 ? `?searchKey=email&searchValue=${email}` : ''
  // }`

  React.useEffect(() => {
    if (!searchValue && !company?.id) formContext.setValue('company', undefined)
  }, [searchValue])

  console.log('COMPAG', state)
  return (
    <>
      <PageHeader />
      <Stack
        direction="row"
        spacing={3}
        sx={{ justifyContent: 'flex-end', mb: 3 }}
      >
        {/* TODO make this format consistent or do it another way */}
        <Link to="add" state={{ company: state }}>
          <Button variant="outlined" endIcon={<PersonAdd />}>
            Add new user
          </Button>
        </Link>
      </Stack>
      <Card sx={{ position: 'relative', p: 2 }}>
        <UsersTable query={query} />
      </Card>

      <Drawer open={useSubmatch('add')}>
        <AddNewUser />
      </Drawer>
      <Drawer open={useSubmatch('edit')}>
        <EditUser />
      </Drawer>

      <Drawer open={useSubmatch('delete')}>
        <DeleteUser />
      </Drawer>
    </>
  )
}
